import { graphql } from 'gatsby';
import { RestorationProjectLayout } from '../../components/entities/restoration-project-layout';
import { createGatsbyPageComponent } from '@shapeable/ui';
export default createGatsbyPageComponent('RestorationProject', { layout: RestorationProjectLayout });

export const query = graphql`
  query RestorationProjectQuery($id: ID!) {
    platform {
      restorationProject(id: $id) {
        __typename
        openGraph { description { text(truncate: { words: 20 }) } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
        _schema { label pluralLabel }
        id slug path name
        latitude longitude
        country { latitude longitude _schema { pluralLabel label } __typename id slug name openGraph { id image { id url url2x } } }
        description { id text }
        species { __typename id name slug openGraph { id title description { id text } image { id url url2x } } }
        objective { id text }
        causeOfDecline { id text }
        reasons { __typename id name slug openGraph { id title description { id text } image { id url url2x } } }
        place { __typename id name latitude longitude }
        organisation { __typename id name slug openGraph { id title description { id text } image { id url url2x } } }
        organisations { __typename id name slug openGraph { id description { id text } title image { id url url2x } } country { id name } } 
        motivations { __typename id name slug openGraph { id title description { id text } image { id url url2x } } } 
        contact { __typename id name slug organisation { id name  } openGraph { id title image { id url url2x } } }
        citation { __typename id slug url name authors { __typename id name slug } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year }
        video { __typename id name slug openGraph { id title description { id text } image { id url url2x } } }
        observations {
          __typename
          startDate endDate
          id name slug openGraph { id title description { id text } image { id url url2x } }
          observationNumber 
          areaOfRestoration
          distanceToNearestKelpForestInMetres
          actionSummary { id text } 
          lessonsLearned { id text } 
          projectImpacts { id text } 
          disturbanceDescription { id text } 
          recordedBy { __typename id slug name openGraph { id image { id url url2x } } }
          species { __typename id slug name openGraph { id image { id url url2x } } }
          transplantAdherenceMethod { __typename id slug name openGraph { id image { id url url2x } } }
          transplantLifeStage { __typename id slug name openGraph { id image { id url url2x } } }
          transplantSource { __typename id slug name openGraph { id image { id url url2x } } }
          causeOfDecline { id text }
          reasons { __typename id name slug openGraph { id title description { id text } image { id url url2x } } }
          objective { id text }
          motivations { __typename id name slug openGraph { id title description { id text } image { id url url2x } } }
          methodologies { __typename id name slug openGraph { id title description { id text } image { id url url2x } } } 
          disturbanceTypes { __typename id name slug openGraph { id title description { id text } image { id url url2x } } } 
          impactTypes { __typename id name slug openGraph { id title description { id text } image { id url url2x } } } 
          totalCost capitalCost planningCost constructionCost inKindCost monitoringCost maintenanceCost costCurrency costYear
          citation { __typename id slug url name authors { __typename id name slug } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year }
          ecologicalPrinciples { __typename id name slug openGraph { id title description { id text } image { id url url2x } } }
          indicatorValues {
            __typename id value startValue endValue numericValue numericStartValue numericEndValue
            indicator { 
              __typename id name title slug precision 
              unit { 
                id slug prefix suffix 
                valueType { id slug } 
              } 
            } 
          }
        }
      }
    }
  }
`;